import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import Helmet from 'react-helmet';
import Slider from 'react-slick';
import styled from 'styled-components';

import HeaderPortfolio from '../components/HeaderPortfolio';
import Heading from '../components/Heading';
import Layout from '../components/Layout';
import Slide from '../components/Slide';
import IcoNext from '../images/ico-slide-next.svg';
import IcoPrevious from '../images/ico-slide-previous.svg';
import imgLogoOG from '../images/logo-hubble-og.png';

const SliderPreviousArrow = ({ className, style, onClick }) => (
  <img
    className={className}
    alt="Previous slide"
    src={IcoPrevious}
    style={{
      ...style,
      display: 'block',
      height: '36px',
      left: '0',
      zIndex: '99',
    }}
    onClick={onClick}
  />
);

const SliderNextArrow = ({ className, style, onClick }) => (
  <img
    className={className}
    alt="Next slide"
    src={IcoNext}
    style={{
      ...style,
      display: 'block',
      height: '36px',
      right: '0',
      zIndex: '99',
    }}
    onClick={onClick}
  />
);

const SliderWrapper = styled.div`
  .slick-dots {
    bottom: -40px;
    margin-left: 40px;
    text-align: left;

    li {
      margin: 0 3px;

      &.slick-active button:before {
        color: ${({ theme }) => theme.linkColor};
      }

      button:before {
        font-size: 10px;
      }
    }
  }
`;

const Wrapper = styled.div`
  background-color: white;
  width: 100%;
  min-height: 85vh;

  .project-details {

    @media ${({ theme }) => theme.breakpoint.sm} {
      padding: 0 50px 50px 50px;
    }

    .item {
      margin: 20px 0;

      h2 {
        margin-bottom: 5px;
        margin-top: 25px;
        font-size: 1.4rem;
        font-weight: bold;
        color: ${({ projectColor }) => projectColor};

        @media ${({ theme }) => theme.breakpoint.sm} {
          font-size: 1.8rem;
        }
      }

      p {
        @media ${({ theme }) => theme.breakpoint.sm} {
          text-align: justify;
        }
      }

      li{
        display: inline;
        &:after{
          content '|';
          margin: 0 8px 0 8px;
          color: #e2e2e2;
        }
        &:last-child:after{
          display: none;
        }
      }

      .technologies {
        list-style-type: none;
      }
    }
  }
`;

const PortfolioHeader = styled.div`
  text-align: center;

  h1 {
    padding: 10px 40px 0 40px;
    background-image: none;
    line-height: 30px;
    -webkit-text-fill-color: initial;

    @media ${({ theme }) => theme.breakpoint.sm} {
      padding: 5px 0 0 0;
      line-height: 51px;
    }
  }
`;

const Subtitle = styled.h2`
  margin-bottom: 30px;
  padding-top: 9px;
  font-size: 20px;
  font-weight: 100;
  text-align: center;
  line-height: 22px;

  @media ${({ theme }) => theme.breakpoint.sm} {
    margin-bottom: 60px;
    font-size: 24px;
  }
`;

const Content = styled.div`
  margin: 30px 0 60px 0;
`;

const slickSettings = {
  centerPadding: '0',
  centerMode: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  prevArrow: <SliderPreviousArrow />,
  nextArrow: <SliderNextArrow />,
};

const renderProjectImages = images =>
  images.map(image => (
    <Slide key={image.id}>
      <Image fluid={image.childImageSharp.fluid} />
    </Slide>
  ));

const Project = ({ data }) => {
  const { project } = data;
  const { color, title } = project;

  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: 'fr-CA' }}>
        <title>
          {`Hubble - Création de sites Web, applications Web et mobiles à Québec - ${title}`}
        </title>
        <meta
          name="description"
          content="Hubble est une agence de développement de sites Web, d'applications mobiles natives et d'applications Web pour tout type d'organisation, entreprises, OBNL ou particulier. Nous sommes situés dans la ville de Québec. "
        />
        <meta property="og:title" content={`Hubble - Portfolio - ${title}`} />
        <meta property="og:site_name" content="Hubble" />
        <meta property="og:url" content="https://teamhubble.com" />
        <meta
          property="og:description"
          content="Hubble est une agence de développement de sites Web, d'applications mobiles natives et d'applications Web pour tout type d'organisation, entreprises, OBNL ou particulier. Nous sommes situés dans la ville de Québec. "
        />
        <meta property="og:type" content="business.business" />
        <meta property="og:image" content={imgLogoOG} />
        <link
          rel="stylesheet"
          href="https://unpkg.com/bootstrap@4.3.1/dist/css/bootstrap-grid.min.css"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>

      <Wrapper projectColor={color}>
        <HeaderPortfolio />

        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-8">
              <PortfolioHeader color={color}>
                <Heading as="h1">{title}</Heading>
                <Subtitle>{project.shortDescription}</Subtitle>
              </PortfolioHeader>

              <SliderWrapper>
                <Slider {...slickSettings}>
                  {renderProjectImages(project.images)}
                </Slider>
              </SliderWrapper>

              <Content
                dangerouslySetInnerHTML={{
                  __html: project.content.childMarkdownRemark.html,
                }}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query QueryProject($slug: String!) {
    project: projectsJson(slug: { eq: $slug }) {
      shortDescription
      slug
      title
      color
      content {
        childMarkdownRemark {
          html
        }
      }
      images {
        id
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default Project;
