import { navigate, Link } from 'gatsby';
import React from 'react';

import styled from 'styled-components';

import imgWave from '../images/bg-portfolio-wave.svg';
import icoBack from '../images/ico-back.svg';
import imgLogo from '../images/logo-hubble.svg';

const Wrapper = styled.header`
  position: relative;
  padding-top: 30px;
  padding-bottom: 45px;
  background-image: linear-gradient(
    222deg,
    ${({ theme }) => theme.brandColor} 8%,
    #18c9b6 48%,
    #009ba4 99%
  );
  color: #ffffff;
  z-index: 99;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: -7px;
    left: 0;
    background-image: url(${imgWave});
    background-position: bottom -1px center;
    background-repeat: no-repeat;
  }
`;

const ButtonBack = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  outline: 0;
`;

const IcoBack = styled.img`
  position: absolute;
  top: 82px;
  left: 12px;
  width: 30px;

  @media ${({ theme }) => theme.breakpoint.sm} {
    width: 50px;
    top: 75px;
    left: 0;
  }
`;

const Logo = styled.img`
  position: relative;
  display: block;
  left: -15px;
  margin: 0 auto;
  width: 145px;
`;

const handleNavigateToHome = event => {
  event.preventDefault();

  navigate('/#portfolio');
};

const HeaderPortfolio = () => (
  <Wrapper>
    <Link to="/">
      <Logo src={imgLogo} alt="Logo Hubble" />
    </Link>

    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center">
          <ButtonBack onClick={handleNavigateToHome}>
            <IcoBack src={icoBack} alt="Retour à l'accueil" />
          </ButtonBack>
        </div>
      </div>
    </div>
  </Wrapper>
);

export default HeaderPortfolio;
